<template>
    <ValidationObserver
        tag="form"
        class="new-comment"
        @submit.prevent="commentHandler"
        ref="observer"
    >
        <router-link to="/auth/profile/">
            <avatar :src="current_user.image_zoom_out"/>
        </router-link>
        <ValidationProvider tag="div" :rules="`required`" v-slot="{errors}" class="new-comment__wrap" vid="comment_text">
            <textarea-autosize
                @keyup.enter.ctrl.native="comment_text += '\n'"
                @keypress.enter.native.exact="commentHandler($event)"
                v-model="comment_text"
                class="new-comment__textarea"
                rows="1"
                :class="{'new-comment__textarea--active':comment_text.length}"
                placeholder="Комментировать"
                :maxlength="1024"
                ref="newComment"
            />
            <span class="v-error animated fadeInDown" v-if="errors.length">
                {{errors[0]}}
            </span>
        </ValidationProvider>
        <button
            v-if="value ? value !== comment_text : comment_text.length"
            href="javascript:void(0)"
            class="new-comment__submit empty-btn"
            :disabled="isCommentSending"
            @click="commentHandler"
            v-tooltip="'Отправить'"
        />
    </ValidationObserver>
</template>

<script>
    import {mapState} from "vuex"
    import session from '@/api/session';

    export default {
        name: "NewComment",
        components: {
            Avatar : () => import('@/components/Avatar')
        },
        props: {
            objId: {
                type: Number,
                required: true
            },
            onAfterComment: {
                type: Function,
                required: false
            },
            contentType: {
                type: String,
                required: true
            },
            value: {
                type: String,
                required: false,
                default: ''
            },
            commentTo: {
                type: Object,
                required: false,
            },
            parentId: {
                type: Number,
                required: false,
            }
        },
        data() {
            return {
                comment_text: this.value,
                isCommentSending: false
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user', 'limits'
            ]),
        },
        methods: {
            async commentHandler(e) {
                e.preventDefault();
                this.isCommentSending = true;
                let isValid = await this.$refs.observer.validate();
                if (isValid) {
                    const comment = {
                        text: this.comment_text,
                        content_type: this.contentType,
                        object_id: this.objId
                    };
                    if (this.commentTo) {
                        comment.parent = this.parentId;
                        comment.answer_to = this.commentTo.id;
                    }
                    try {
                        let request = await session.post(`/api/v1/comment/`, comment);
                        this.onAfterComment(request.data);
                        this.comment_text = '';
                        this.$refs.observer.reset();
                    } catch (e) {
                        this.$swal('Ошибка!', 'При отправке комментария произошла ошибка на сервере', 'error');
                        console.error(e);
                    }
                    this.isCommentSending = false;
                }
            }
        },
        created() {
            if (this.commentTo) {
                this.$nextTick(function () {
                    this.$refs.newComment.$el.focus();
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '#sass/v-style';

    .new-comment {
        display: flex;
        width: 100%;

        &__wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-left: 10px;
        }

        &__textarea {
            width: 100%;
            border: none;
            resize: none;
            outline: none;
            padding-top: 10px;

            &::placeholder {
                font-size: 1rem;
                color: $text-gray;
            }

            &--active {
                border: 1.5px solid $gold;
                padding: 10px 20px;
            }
        }

        &__submit {
            @include icon($send-comment-icon, 25px);
            align-self: center;
            margin-left: 10px;

            &:active {
                opacity: 0.7;
            }
        }
    }

</style>
